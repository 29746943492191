<template>
  <div
    class="row csn-change-password csn-user-account-section"
    id="user-account"
  >
    <div class="col-md-12">
      <div class="container">
        <section class="csn-change-password-upper-section">
          <h1>{{ t('my_profile') }}</h1>
          <p>{{ t('description') }}</p>
        </section>
        <section class="csn-change-password-lower-section">
          <h2>{{ t('platin_account_password') }}</h2>
          <Message :type="messageType" :message="message" :hasBottom="true" />
          <Form :ref="formRef">
            <div
              class="row-fluid form-change-password csn-change-password-form"
            >
              <PasswordField
                :name="name.CURRENT_PASSWORD"
                :label="t('current_password')"
                v-model="formData[name.CURRENT_PASSWORD]"
                :validators="[validator.required]"
                :serverError="serverError[name.CURRENT_PASSWORD]"
                autocomplete="off"
              />
              <PasswordField
                :name="name.NEW_PASSWORD"
                :label="t('new_password')"
                v-model="formData[name.NEW_PASSWORD]"
                :validators="[validator.required]"
                :serverError="serverError[name.NEW_PASSWORD]"
                autocomplete="off"
              />
              <PasswordField
                :name="name.REPEAT_NEW_PASSWORD"
                :label="t('repeat_new_password')"
                v-model="formData[name.REPEAT_NEW_PASSWORD]"
                :validators="[
                  validator.required,
                  validator.isSameAs(name.NEW_PASSWORD),
                ]"
                :serverError="serverError[name.REPEAT_NEW_PASSWORD]"
                autocomplete="off"
              />
              <div class="form-group">
                <button
                  class="btn casino-btn-ver-1 casino-btn casino-btn-theme-based"
                  type="submit"
                  @click="submitForm"
                  :disabled="isSubmitting"
                >
                  <ButtonLoader v-if="isSubmitting" />
                  <span v-else>
                    {{ t('update_password') }}
                  </span>
                </button>
              </div>
            </div>
          </Form>
        </section>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapMutations, mapGetters } from 'vuex'
import {
  CHANGE_PASSWORD,
  EMPTY_STRING,
  MUST_CHANGE_PASSWORD,
  SET_MUST_CHANGE_PASSWORD,
  Module,
  IS_LOGGED,
} from '@/constants'
import { dest } from '@/helpers'
import { formMixin } from '@/mixins'
import { AuthApi } from '@/api'

const formName = 'changePassword'
const name = {
  CURRENT_PASSWORD: `${formName}old_password`,
  NEW_PASSWORD: `${formName}password`,
  REPEAT_NEW_PASSWORD: `${formName}password_again`,
}
const formData = {
  [name.CURRENT_PASSWORD]: EMPTY_STRING,
  [name.NEW_PASSWORD]: EMPTY_STRING,
  [name.REPEAT_NEW_PASSWORD]: EMPTY_STRING,
}

export default {
  name: CHANGE_PASSWORD,
  mixins: [formMixin],
  components: {
    Form: () => import('@/components/FormData'),
    Message: () => import('@/components/atoms/Message'),
    ButtonLoader: () => import('@/components/ButtonLoader'),
    PasswordField: () => import('@/components/PasswordField'),
  },
  data: () => ({
    formData: { ...formData },
  }),
  computed: {
    t() {
      return this.$createComponentTranslator(CHANGE_PASSWORD)
    },
    name: () => name,
    ...mapState(Module.USER, [MUST_CHANGE_PASSWORD]),
    ...mapGetters({ isLogged: dest([Module.USER, IS_LOGGED]) }),
  },
  watch: {
    formData: {
      deep: true,
      handler(currentFormData) {
        this.message && (this.message = null)
        this.messageType && (this.messageType = null)

        if (!Object.keys(this.serverError).length) {
          return
        }

        Object.keys(this.serverError).forEach((key) => {
          if (currentFormData[key] === this.formDataSubmitCopy[key]) {
            return
          }

          const newServerError = { ...this.serverError }
          delete newServerError[key]

          this.serverError = newServerError
        })
      },
    },
  },
  methods: {
    ...mapMutations(Module.USER, [SET_MUST_CHANGE_PASSWORD]),
    async submitForm() {
      const { isValid } = this.$refs[this.formRef].getValidation()

      if (!isValid) {
        return
      }

      this.isSubmitting = true

      try {
        const {
          CURRENT_PASSWORD,
          NEW_PASSWORD,
          REPEAT_NEW_PASSWORD,
        } = this.name

        const request = {
          user_change_password: {
            old_password: this.formData[CURRENT_PASSWORD],
            password: {
              first: this.formData[NEW_PASSWORD],
              second: this.formData[REPEAT_NEW_PASSWORD],
            },
          },
        }

        const response = await AuthApi.changePassword(request)

        if (!this.validateResponse(response)) {
          return
        }

        const { data, state, msg } = response

        if (state === this.responseState.ERROR) {
          const serverError = {}

          Object.keys(data.errors || {}).forEach((key) => {
            serverError[`${formName}${key}`] = data.errors[key].msg
          })

          this.serverError = serverError
          this.formDataSubmitCopy = { ...this.formData }
          return
        }

        this.serverError = {}
        this.formDataSubmitCopy = {}
        await this.$refs[this.formRef].reset()
        this.messageType = this.messageTypeEnum.SUCCESS
        this.message = msg
        this.MUST_CHANGE_PASSWORD && this.SET_MUST_CHANGE_PASSWORD(false)
      } catch (error) {
        console.log(error)
      } finally {
        this.isSubmitting = false
      }
    },
  },
  beforeRouteLeave(to, from, next) {
    if (this.isLogged && this.MUST_CHANGE_PASSWORD) {
      return
    }

    this.formData = { ...formData }

    next()
  },
}
</script>
